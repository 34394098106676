export const emptyCart = (dispach, items, rmv, callback) => {
  (() => {
    for (let item of items) dispach(rmv(item._id));
  })();
  localStorage.removeItem("cart");
  callback();
};

export function currencyFormatter(value) {
  if (!Number(value)) return "";

  const amount = new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  }).format(value / 100);

  return `${amount}`;
}

export function tableResp(element) {
  if (element.current) {
    let thead = element.current.children[0];
    let tbody = element.current.children[1];
    let labels = Array.from(thead.children[0].children).map((th) => {
      return th.innerText;
    });
    Array.from(tbody.children).map((tr) => {
      return Array.from(tr.children).forEach((td, i) => {
        td.setAttribute("data-label", labels[i % labels.length]);
      });
    });
  }
}
