import React, { useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import isAuth from "../../auth/AuthService";
import { useSelector } from "react-redux";
import { getProducts } from "../api/apiCore";
import { useOnlineStatus, useOutsideAlerter } from "../api/customHooks";
import ModalSearchResult from "../partials/ModalSearchResult";
import signout from "../../user/Signout";

const Menu = () => {
  let countItem = useSelector((state) => state.cart.count);

  const navigate = useNavigate();
  const online = useOnlineStatus();
  const navbarRef = useRef();
  const navBtnRef = useRef();

  // Close opened navbar on click outside it.
  useOutsideAlerter(navbarRef, navBtnRef);

  // Search navbar form
  const [products, setProducts] = useState([]);
  const [searchData, setsearchData] = useState({ search: "" });
  const [openResult, setOpenResult] = useState(false);
  const [out, setOut] = useState(false);

  const handleChange = (e) => {
    setsearchData({ ...searchData, [e.target.id]: e.target.value });
  };

  const searchSubmit = (e) => {
    e.preventDefault();
    let { search } = searchData;
    if (search) {
      getProducts({ search: search || undefined }).then((res) => {
        setProducts(res.products);
        setOpenResult(true);
      });
    } else setProducts([]);
  };

  // Le modal ainsi que les differentes methods ainsi que l'inversement du flux de données
  const leModal = () => {
    if (openResult)
      return (
        <ModalSearchResult
          width={400}
          items={products}
          open={openResult}
          closeClick={() => setOpenResult(false)}
          className="bproduct-0 rounded position-fixed bottom-50 start-0 z-2"
        />
      );
  };

  const location = useLocation();

  const isActive = (path) => {
    if (location.pathname === path) {
      return { color: "#ffbf00" };
    } else {
      return { color: "#fff" };
    }
  };

  return (
    <div>
      <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark px-1">
        <Link className="navbar-brand" to="/" title="Accueil">
          Ecommerce
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navBar"
          aria-controls="navBar"
          aria-expanded="false"
          ref={navBtnRef}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div ref={navbarRef} className="collapse navbar-collapse" id="navBar">
          <ul className="nav navbar-nav me-auto gap-3 gap-md-0 pb-3 pb-md-0">
            <li className="nav-item  bg-transparent border-0 ps-0">
              <Link
                style={isActive("/")}
                className="nav-Link ps-2"
                to="/"
                title="Accueil"
              >
                <i className="fa-solid fa-house fa-lg"></i>
                <span className="sr-only">(current)</span>
              </Link>
            </li>
            <li className="nav-item bg-transparent border-0 ps-0">
              <Link
                style={isActive("/shop")}
                className="nav-Link ps-2"
                to="/shop"
                title="Boutique"
              >
                <i className="fa-brands fa-shopify fa-lg"></i>
                <span className="sr-only">(current)</span>
              </Link>
            </li>
            {isAuth() && (
              <>
                <li className="nav-item  bg-transparent border-0 ps-0">
                  <Link
                    style={isActive(
                      `${
                        isAuth() && isAuth().user.role === 1 ? "/admin" : ""
                      }/dashboard`
                    )}
                    className="nav-Link ps-2 text-decoration-none"
                    to={`${
                      isAuth() && isAuth().user.role === 1 ? "/admin" : ""
                    }/dashboard`}
                    title="Tableau De Bord"
                  >
                    <i className="fa-regular fa-user fa-lg"></i>
                    <span
                      className="text-white"
                      title={online ? "En Ligne" : "Hors Ligne"}
                    >
                      {online ? "👌🏼" : "❌"}
                    </span>
                    <span className="sr-only">(current)</span>
                  </Link>
                </li>
              </>
            )}
          </ul>
          <ul className="nav navbar-nav ms-auto d-grid d-md-flex align-items-center gap-3 gap-md-0">
            {(!isAuth() || out) && (
              <>
                <li className="nav-item bg-transparent border-0 ps-0">
                  <Link
                    style={isActive("/signin")}
                    className="nav-Link ps-2"
                    to="/signin"
                    title="Connection"
                  >
                    <i className="fas fa-sign-in-alt fa-lg"></i>
                    <span className="sr-only">(current)</span>
                  </Link>
                </li>
                <li className="nav-item bg-transparent border-0 ps-0">
                  <Link
                    style={isActive("/signup")}
                    className="nav-Link ps-2"
                    to="signup"
                    title="Inscription"
                  >
                    <i className="fas fa-user-plus fa-lg"></i>
                    <span className="sr-only">(current)</span>
                  </Link>
                </li>
              </>
            )}
            {isAuth() && (
              <>
                <li className="nav-item bg-transparent border-0 ps-0">
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      signout();
                      navigate("/signin");
                      setOut(true);
                    }}
                    to={"/signout"}
                    style={isActive("/signout")}
                    className="nav-link ps-2 text-white"
                    title="Déconnection"
                  >
                    <i className="fa fa-power-off fa-lg"></i>
                  </Link>
                </li>
              </>
            )}
            <li className="nav-item bg-transparent border-0 ps-0">
              <Link
                style={isActive("/cart")}
                className="nav-link pe-5 position-relative text-white py-0"
                to={"/cart"}
                title="Panier"
              >
                <i className="fa fa-cart-shopping fa-lg"></i>
                <span
                  className="badge bg-danger rounded-circle position-absolute"
                  style={{ bottom: "15px" }}
                >
                  {countItem}
                </span>
              </Link>
            </li>
            <li className="nav-item bg-transparent border-0 ps-0">
              <form onSubmit={searchSubmit} className="ms-auto">
                <div className="d-grid position-relative">
                  <input
                    onChange={handleChange}
                    placeholder="Recherche"
                    id="search"
                    type="search"
                    className="form-control rounded-pill pb-2 px-2 border border-primary"
                  />
                  <div className="input-group-apprend position-absolute end-0">
                    <button
                      type="submit"
                      className="btn btn-primary py-2 rounded-circle border-0"
                    >
                      <i className="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </form>
            </li>
          </ul>
        </div>
      </nav>
      {products.length > 0 && leModal()}
    </div>
  );
};
export default Menu;
