import React, { useRef } from "react";
import ProductCard from "../product/Card";
import SearchCountResult from "./SearchCountResult";
import { useOutsideAlerter } from "../api/customHooks";

const ModalSearchResult = ({ items = [], handleClick, closeClick, open }) => {
    
  const modalRef = useRef();
  const modalBtnRef = useRef();
     // Close opened navbar on click outside it.
  useOutsideAlerter(modalRef, modalBtnRef);
  return (
    <dialog
      open={open}
      className="dialog border-0 z-2 bg-secondary vw-100 vh-100 position-fixed top-0 p-0 pt-2 overflow-auto"
      ref={modalRef}
    >
      <div className="modal modal-content w-100 mx-auto pt-5">
        <div className="modal-header bg-primary text-white">
          <h6 className="modal-title" id="exampleModalLabel">
            {items.length > 0 && <SearchCountResult products={items} />}
          </h6>
          <button
            type="button"
            className="btn-close text-danger"
            onClick={() => closeClick()}
            ref={modalBtnRef}
          ></button>
        </div>
        <div className="modal-body">
          <div className="container-fluid">
            <div className="row">
              {items.length &&
                items.map((product) => (
                  <div
                    key={product._id}
                    data-id={product._id}
                    className="col-sm-6 col-md-4 col-lg-3"
                  >
                    <ProductCard
                      product={product}
                      onShowModal={(e, item) => {
                        item = product;
                        handleClick(e, product);
                      }}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer bg-primary text-white">footer</div>
    </dialog>
  );
};

export default ModalSearchResult;
