import React, { useEffect, useState } from "react";
import { categoriesList, getProducts } from "../api/apiCore";
import ProductCard from "./Card";
import SearchCountResult from "../partials/SearchCountResult";

const Search = ({ handleClick }) => {
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [searchData, setsearchData] = useState({ search: "", category: "" });

  const handleChange = (e) => {
    setsearchData({ ...searchData, [e.target.id]: e.target.value });
  };

  const searchSubmit = (e) => {
    e.preventDefault();
    let { search, category } = searchData;
    if (search || category) {
      getProducts({ search: search || undefined, category }).then((res) => {
        setProducts(res.products);
      });
    } else setProducts([]);
  };

  useEffect(() => {
    categoriesList().then((res) => setCategories(res));
  }, []);

  return (
    <div className="my-3 px-5">
      <form onSubmit={searchSubmit}>
        <div className="container">
          <div className="row mx-auto">
            <div className="d-grid col-md-3 py-2 py-sm-1 py-0">
              <select
                onChange={handleChange}
                id="category"
                className="btn btn-outline-dark rounded-pill pb-2 me-2"
              >
                <option value="">Choisir Categorie</option>
                {categories &&
                  categories.map((category, i) => (
                    <option key={category._id} value={category._id}>
                      {category.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="d-grid position-relative col-md-6 col-lg-5 py-2 py-sm-1 py-0">
              <input
                onChange={handleChange}
                placeholder="Recherche"
                id="search"
                type="search"
                className="form-control rounded-pill pb-2 px-2 border border-primary"
              />
              <div
                style={{ zIndex: 1, right: "12px" }}
                className="input-group-apprend position-absolute top-0 py-2 py-sm-1 py-0"
              >
                <button className="btn btn-primary py-2 rounded-circle border-0">
                  <i className="fas fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      {products.length>0 && (
        <SearchCountResult products={products} />
      )}
      <div className="row">
        {products &&
          products.map((product, i) => (
            <div
              key={i}
              data-id={product._id}
              className="col-sm-6 col-md-4 col-lg-3 mx-auto"
            >
              <ProductCard
                product={product}
                onShowModal={(e, item) => {
                  item = product;
                  handleClick(e, product);
                }}
              />
            </div>
          ))}
      </div>
    </div>
  );
};
export default Search;
