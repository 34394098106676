import React from 'react'

function SearchCountResult({products}) {
    return (
      products &&
      products.length > 0 && (
        <div className="my-3">
          <h4>
            Resultat de la Recherche: {products.length} produits(s) trouvé.
          </h4>
        </div>
      )
    );
}

export default SearchCountResult