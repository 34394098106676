let myState = {
    user: {}
}
const authRducer = (state = myState, action) => {
    switch(action.type) {
        default: {
            return state
        }
    }
}

export default authRducer