import { API_URL } from "../config";
import toastr from "toastr";
import isAuth from "../auth/AuthService";

const signout = () => {
  // Signout
  if (isAuth()) {
    fetch(`${API_URL}api/signout`)
      .then(() => {
        toastr.info("Vous Êtes Déconnecter.", "", {
          positionClass: "toast-top-center",
        });
        localStorage.removeItem("jwt_info");
      })
      .catch((err) => console.log(err));
  } else {
    toastr.error("Vous Êtes Déjà Déconnecter!", "", {
      positionClass: "toast-top-center",
    });
  }
};
export default signout;
